<template>
  <div style="max-height: 300px; overflow-y: auto;">
    <!-- Muestra los valores cuando no están expandidos -->
    <v-chip
      small
      class="ma-1"
      v-if="!isExpanded || itemData.length === 1"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ formatData(itemData[0]) | truncate(50) }}</span>
        </template>
        <span class="text-style">{{ formatData(itemData[0]) }}</span>
      </v-tooltip>
    </v-chip>

    <!-- Muestra los valores cuando están expandidos -->
    <v-chip
      v-for="(data, index) in itemData"
      :key="index"
      small
      class="ma-1"
      v-if="isExpanded"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ formatData(data) | truncate(50) }}</span>
        </template>
        <span class="text-style">{{ formatData(data) }}</span>
      </v-tooltip>
    </v-chip>

    <!-- Botón para expandir/colapsar -->
    <v-chip
      x-small
      class="ma-1"
      v-if="itemData.length > 1"
      @click="toggleExpansion"
    >
      {{ isExpanded ? '-' : '+' }} {{ itemData.length - 1 }} {{ $t('common.titles.others') }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'ShowMoreComponent',

  props: {
    item: {
      type: Object,
      required: true,
    },
    secondaryField: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isExpanded: false,
  }),

  computed: {
    itemData() {
      return this.resolveDataPath(this.item, this.secondaryField.split('.'));
    }
  },

  methods: {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    },

    resolveDataPath(obj, pathParts) {
      // Get the first part of path (e.g., 'storageDevices')
      const firstPart = pathParts[0];
      
      // Get the value of the first part
      let data = obj[firstPart];
      
      // If data is not an array, convert it to an array for consistent processing
      if (!Array.isArray(data)) {
        data = data ? [data] : [];
      }
      
      // If there are nested fields to resolve (e.g., 'name' in 'storageDevices.name')
      if (pathParts.length > 1) {
        // Get remaining path parts
        const remainingParts = pathParts.slice(1);
        
        // For each item in the array, extract the nested property
        return data.map(item => {
          // Resolve nested property for each item
          let value = item;
          for (const part of remainingParts) {
            if (value && typeof value === 'object') {
              value = value[part];
            } else {
              value = undefined;
              break;
            }
          }
          return value;
        }).filter(Boolean); // Remove any undefined values
      }
      
      // If no nested fields, just return the array
      return data;
    },

    formatData(data) {
      return typeof data === 'object' ? JSON.stringify(data) : data;
    }
  },

  filters: {
    truncate(text, length, clamp = '...') {
      if (!text) return '';
      return text.length > length ? text.slice(0, length) + clamp : text;
    }
  }
}
</script>

<style scoped>
.text-style {
  font-size: 12px;
  color: #f5f5f5;
}
</style>
